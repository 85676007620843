'use strict';

angular
	.module('bringgApp')
	.controller(
		'TwoFactorAuthenticationController',
		function (
			$scope,
			Authentication,
			$location,
			$timeout,
			$translate,
			toastr,
			MainNavigationVer2Service,
			MerchantConfigurations,
			vcRecaptchaService,
			RECAPTCHA_SITE_KEY,
			$routeParams,
			$interval
		) {
			$scope.code = {
				firstDigit: '',
				secondDigit: '',
				thirdDigit: '',
				fourthDigit: '',
				fifthDigit: '',
				sixthDigit: ''
			};
			$scope.userEmail = '';
			$scope.minutesToExpiration = 0;
			$scope.otpVerificationFailed = false;
			$scope.resendDisabled = false;
			$scope.isLoggedIn = false;
			$scope.show_captcha = false;
			$scope.recaptcha = null;
			$scope.widgetId = null;
			$scope.model = {
				key: RECAPTCHA_SITE_KEY
			};
			$scope.expirationInterval = null;

			$scope.setWidgetId = function (widgetId) {
				$scope.widgetId = widgetId;
				if ($scope.show_captcha) {
					vcRecaptchaService.reload($scope.widgetId);
				}
			};

			$scope.setRecaptcha = function (recaptcha) {
				$scope.recaptcha = recaptcha;
				const code = Object.values($scope.code).join('');

				if (code.length === 6) {
					$scope.sendCode(code);
				}
			};

			$scope.cbExpiration = function () {
				$scope.recaptcha = null;
			};

			$scope.handlePressedKey = function (event) {
				const codeObjKeys = Object.keys($scope.code);
				const currentInputIndex = codeObjKeys.indexOf(event.target.id);
				if (
					currentInputIndex > 0 &&
					!$scope.code[event.target.id]?.length &&
					(event.key === 'Backspace' || event.key === 'Delete')
				) {
					$timeout(() => {
						document.getElementById(codeObjKeys[currentInputIndex - 1]).focus();
					}, 10);
				}
			};

			$scope.handleFocus = function (digitKey) {
				document.getElementById(digitKey).setSelectionRange(0, 1);
			};

			$scope.handleEvent = function (digitKey) {
				const codeKeys = Object.keys($scope.code);
				const inputIndex = codeKeys.indexOf(digitKey);
				let inputValue = $scope.code[digitKey];

				if (inputValue && !inputValue.match(/^[0-9]+$/)) {
					$scope.code[digitKey] = '';
					return;
				}

				// Handle paste of code
				if (inputValue?.length >= 6) {
					const lastSixDigits = inputValue.slice(-6).split('');
					lastSixDigits.forEach((digit, index) => {
						$scope.code[codeKeys[index]] = digit;
					});
				}

				// Move focus to next input if single digit entered
				if (inputValue?.length === 1 && inputIndex >= 0 && inputIndex < codeKeys.length - 1) {
					focusNext(codeKeys[inputIndex + 1]);
				}

				// if number was paster to the input which already has value
				if (inputValue?.length > 1 && inputValue?.length < 6) {
					$scope.code[digitKey] = inputValue.slice(-1); // Keep only the last digit
					if (inputIndex < codeKeys.length - 1) {
						focusNext(codeKeys[inputIndex + 1]);
					}
				}

				const code = Object.values($scope.code).join('');
				if (code.length === 6) {
					$scope.sendCode(code);
					document.getElementById(digitKey).blur();
				}
			};

			$scope.captchaCB = function (recaptchaToken) {
				if (recaptchaToken) {
					$scope.stoken = recaptchaToken;
					$scope.show_captcha = true;
					if ($scope.widgetId !== null) {
						vcRecaptchaService.reload($scope.widgetId);
					}
				} else {
					$scope.show_captcha = false;
				}

				$scope.$apply();
			};

			$scope.sendCode = function (code) {
				const recaptchaResult = $scope.show_captcha ? vcRecaptchaService.getResponse($scope.widgetId) : null;

				Authentication.loginWithOtp(code, recaptchaResult, $scope.recaptcha_qa_token, $scope.captchaCB).catch(
					() => {
						$scope.otpVerificationFailed = true;
						$scope.$apply();
					}
				);
			};

			$scope.isOTPExpired = function () {
				return new Date().toISOString() >= $scope.expirationTime;
			};

			$scope.requestNewCode = function () {
				$scope.resendDisabled = true;
				Authentication.requestNewCode($scope.userEmail)
					.then(response => {
						$scope.startExpirationTimeInterval(response.expiration);
						toastr.success($translate.instant('EMAIL_VERIFICATION.NEW_EMAIL_SENT'));

						$scope.$apply();
					})
					.catch(() => {
						$scope.resendDisabled = false;
						toastr.error($translate.instant('EMAIL_VERIFICATION.FAILED_TO_SEND_NEW_EMAIL'));
					});
			};

			if ($routeParams.recaptcha_qa_token !== null) {
				$scope.recaptcha_qa_token = $routeParams.recaptcha_qa_token;
			}

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				$scope.isLoggedIn = isLoggedIn;

				$scope.init();
			});

			$scope.init = () => {
				$scope.userEmail = Authentication.loginData.email;
				$scope.startExpirationTimeInterval(Authentication.loginData.expirationTime);

				if (!$scope.userEmail) {
					if ($scope.isLoggedIn) {
						MerchantConfigurations.$refresh().then(function () {
							$location.path(MainNavigationVer2Service.getFirstAvailableViewLocation());
						});
					} else {
						$location.path('/login');
					}
				}
			};

			$scope.startExpirationTimeInterval = function (expirationTime) {
				$scope.expirationTime = expirationTime;

				$scope.minutesToExpiration = getExpirationMinutes($scope.expirationTime);

				$scope.expirationInterval = $interval(function () {
					$scope.minutesToExpiration = getExpirationMinutes($scope.expirationTime);

					if ($scope.minutesToExpiration === 0) {
						$scope.stopExpirationInterval();
					}
				}, 60000);
			};

			$scope.stopExpirationInterval = function () {
				$interval.cancel($scope.expirationInterval);
				$scope.expirationInterval = undefined;
			};

			function focusNext(nextKey) {
				const nextElement = document.getElementById(nextKey);
				if (nextElement) {
					nextElement.focus();
				}
			}

			function getExpirationMinutes(expirationTime) {
				var expirationDate = moment(expirationTime);
				// TODO: possible issue with user DST changed and server not using DST
				return Math.round(moment.duration(expirationDate.diff(moment())).asMinutes()) ?? 1;
			}
		}
	);
