'use strict';

angular.module('bringgApp').factory('$exceptionHandler', function ($log, AirbrakeService, SentryService) {
	var shouldNotifyExceptions = Math.floor(Math.random() * 100) % 10 === 0; // 1/10

	return function (exception, cause) {
		try {
			if (shouldNotifyExceptions) {
				AirbrakeService.notify(exception, { angular_cause: cause });
				SentryService.notify(exception, { angular_cause: cause });
			}
		} catch (error) {
			$log.warn('Airbrake failed to send:', error);
			$log.warn('Sentry failed to send:', error);
		} finally {
			$log.error(exception);
		}
	};
});
