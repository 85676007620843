'use strict';

angular
	.module('bringgApp')
	.controller(
		'DriverAppConfigurationController',
		function (
			$scope,
			DriverConfigurations,
			Authentication,
			$rootScope,
			toastr,
			MerchantConfigurations,
			MerchantConfigurationService,
			$translate
		) {
			$scope.current_user = Authentication.currentUser();
			$scope.beta = Authentication.currentUser().beta;

			$scope.custom_driver_app_colors = {
				use_custom: false,
				custom_hex: '#9c2424',
				preview_color: '#61B3FF' // default bringg
			};

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.allowToConfigureCustomAttribute = featureFlags.allow_to_configure_custom_attribute;
			});

			// if you change this update the test
			$scope.networkValidationAddressRegex =
				'^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\\-]*[A-Za-z0-9])$';

			$scope.data = { automaticDriverFlow: false };
			$scope.merchantConfiguration = MerchantConfigurations;
			$scope.automaticCheckInFieldValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKIN.MANUAL') },
				{ value: 1, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKIN.AUTOMATIC') },
				{ value: 2, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKIN.SUGGESTED') },
				{ value: 3, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKIN.SILENT_REPORT') }
			];

			$scope.automaticCheckOutFieldValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKOUT.MANUAL') },
				{ value: 1, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKOUT.AUTOMATIC') },
				{ value: 2, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKOUT.SUGGESTED') },
				{ value: 3, label: $translate.instant('DRIVER_APP.AUTOMATIC_CHECKOUT.SILENT_REPORT') }
			];

			$scope.automaticStartFieldOptionValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP.AUTO_START_ORDERS_OPTIONS.DISABLED') },
				{ value: 1, label: $translate.instant('DRIVER_APP.AUTO_START_ORDERS_OPTIONS.ENABLED') },
				{ value: 2, label: $translate.instant('DRIVER_APP.AUTO_START_ORDERS_OPTIONS.SUGGEST') }
			];

			$scope.collapseCustomAttributeValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP_CUSTOM_ATTRIBUTES_COLLAPSED') },
				{ value: 1, label: $translate.instant('DRIVER_APP_CUSTOM_ATTRIBUTES_EXPANDED') }
			];

			$scope.automaticStartFieldTypeValues = [
				{ value: 1, label: $translate.instant('DRIVER_APP.AUTO_START_ORDERS_TYPES.TIME') },
				{ value: 2, label: $translate.instant('DRIVER_APP.AUTO_START_ORDERS_TYPES.PRIORITY') },
				{ value: 3, label: $translate.instant('DRIVER_APP.AUTO_START_ORDERS_TYPES.DISTANCE') }
			];

			$scope.pickupModeValues = [
				{ value: 1, label: $translate.instant('DRIVER_APP.PICK_UP_MODE.LOCATION') },
				{ value: 2, label: $translate.instant('DRIVER_APP.PICK_UP_MODE.INVENTORY_PICKUP') }
				//{'value': 3, 'label': $translate.instant('DRIVER_APP.PICK_UP_MODE.INVENTORY_DROP_OFF')},
				//{'value': 4, 'label': $translate.instant('DRIVER_APP.PICK_UP_MODE.INVENTORY_PICKUP_DROP_OFF')},
			];

			$scope.taskOrderingMethods = [
				{ value: 0, label: $translate.instant('DRIVER_APP.ORDER_ORDERING_METHODS.DRIVER_DECIDE') },
				{ value: 1, label: $translate.instant('DRIVER_APP.ORDER_ORDERING_METHODS.PRIORITY') },
				{ value: 2, label: $translate.instant('DRIVER_APP.ORDER_ORDERING_METHODS.TIME') },
				{ value: 3, label: $translate.instant('DRIVER_APP.ORDER_ORDERING_METHODS.DISTANCE_FROM_DRIVER') }
			];

			$scope.cancelTaskAllowedWayPointValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP.CANCEL_TASK_ALLOWED_WAY_POINT.PICKUP') },
				{ value: 1, label: $translate.instant('DRIVER_APP.CANCEL_TASK_ALLOWED_WAY_POINT.DROP_OFF') },
				{ value: 2, label: $translate.instant('DRIVER_APP.CANCEL_TASK_ALLOWED_WAY_POINT.ANY') }
			];

			$scope.preShiftAssignmentsValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP.PRE_SHIFT_ASSIGNMENTS.DISABLED') },
				{ value: 1, label: $translate.instant('DRIVER_APP.PRE_SHIFT_ASSIGNMENTS.ENABLED') },
				{ value: 2, label: $translate.instant('DRIVER_APP.PRE_SHIFT_ASSIGNMENTS.MANDATORY') }
			];

			$scope.driverConfigurationClusterModeValues = [
				{ value: 0, label: $translate.instant('DRIVER_APP.CLUSTER_MODE.NO_CLUSTER') },
				{ value: 1, label: $translate.instant('DRIVER_APP.CLUSTER_MODE.LOCATION') },
				{ value: 2, label: $translate.instant('DRIVER_APP.CLUSTER_MODE.INVENTORY') }
			];

			$scope.driverConfigurationClusterModeInventoryValues = [
				{ value: 2, label: $translate.instant('DRIVER_APP.CLUSTER_MODE_INVENTORY.PICKUP') },
				{ value: 3, label: $translate.instant('DRIVER_APP.CLUSTER_MODE_INVENTORY.DROPOFF') },
				{ value: 4, label: $translate.instant('DRIVER_APP.CLUSTER_MODE_INVENTORY.PICKUP_AND_DROPOFF') }
			];

			$scope.clusterModeInfo = {
				clusterModeType: 0,
				inventoryModeType: 2
			};

			DriverConfigurations.get().then(function (data) {
				$scope.driverConfiguration = data;

				if ($scope.driverConfiguration.driver_app_color) {
					$scope.custom_driver_app_colors.use_custom = true;
					$scope.custom_driver_app_colors.custom_hex = $scope.driverConfiguration.driver_app_color;
					$scope.custom_driver_app_colors.preview_color = $scope.driverConfiguration.driver_app_color;
				}

				$scope.driverConfiguration.reminder_after_start_minutes = _.isNumber(
					$scope.driverConfiguration.reminder_after_start_minutes
				)
					? $scope.driverConfiguration.reminder_after_start_minutes
					: 10;
				$scope.driverConfiguration.reminder_after_start_snooze_minutes = _.isNumber(
					$scope.driverConfiguration.reminder_after_start_snooze_minutes
				)
					? $scope.driverConfiguration.reminder_after_start_snooze_minutes
					: 10;
				$scope.driverConfiguration.reminder_after_end_minutes = _.isNumber(
					$scope.driverConfiguration.reminder_after_end_minutes
				)
					? $scope.driverConfiguration.reminder_after_end_minutes
					: 10;
				$scope.driverConfiguration.collapse_custom_attribute = _.isNumber(
					$scope.driverConfiguration.collapse_custom_attribute
				)
					? $scope.driverConfiguration.collapse_custom_attribute
					: 0;

				$scope.data.automaticDriverFlow =
					!!$scope.driverConfiguration.automatic_checkin || !!$scope.driverConfiguration.automatic_checkout;

				$scope.driverConfiguration.must_approve_inventory =
					$scope.driverConfiguration.must_approve_inventory || false;
				$scope.driverConfiguration.allow_reject_inventory =
					$scope.driverConfiguration.allow_reject_inventory || false;
				$scope.driverConfiguration.allow_editing_inventory =
					$scope.driverConfiguration.allow_editing_inventory || false;
				$scope.driverConfiguration.route_selection_is_allowed =
					$scope.driverConfiguration.route_selection_is_allowed || false;

				if (!$scope.driverConfiguration.pre_shift_assignments) {
					$scope.driverConfiguration.pre_shift_assignments = {
						should_select_team: 0,
						should_select_vehicle: 0
					};
				} else if (!$scope.driverConfiguration.pre_shift_assignments.should_select_team) {
					Object.assign($scope.driverConfiguration.pre_shift_assignments, { should_select_team: 0 });
				} else if (!$scope.driverConfiguration.pre_shift_assignments.should_select_vehicle) {
					Object.assign($scope.driverConfiguration.pre_shift_assignments, { should_select_vehicle: 0 });
				}

				$scope.clusterModeInfo = getClusterModeInfo(
					$scope.driverConfiguration.cluster_mode,
					$scope.driverConfiguration.cluster_mode_type
				);
			});

			$scope.updateDriverConfiguration = function () {
				$scope.driverConfiguration.driver_app_color = $scope.custom_driver_app_colors.use_custom
					? $scope.custom_driver_app_colors.custom_hex
					: null;

				const clusterModeType = Number($scope.clusterModeInfo.clusterModeType);

				$scope.driverConfiguration.cluster_mode = Boolean(clusterModeType);
				$scope.driverConfiguration.cluster_mode_type = getClusterModeType(
					clusterModeType,
					$scope.clusterModeInfo.inventoryModeType
				);

				Promise.all([
					DriverConfigurations.update($scope.driverConfiguration),
					MerchantConfigurationService.update($scope.merchantConfiguration)
				])
					.then(function (responses) {
						if (responses[0].success) {
							return toastr.success('Update saved, thank you');
						}

						toastr.error(responses[0].message);
					})
					.catch(function () {
						toastr.error('Failed updating merchant');
					});
			};

			/* Magic numbers in 2 functions below described by next table
            +---------------------+------------+----------+-----------------+-----------------+-----------------+
            | Cluster Orders By ->| No cluster | Location | Inventory ->    | Inventory ->    | Inventory ->    |
            |                     |            |          | Pickup          | Dropoff         | Pickup&Dropoff  |
            +---------------------+------------+----------+-----------------+-----------------+-----------------+
            | cluster_mode        | false      | true     | true            | true            | true            |
            +---------------------+------------+----------+-----------------+-----------------+-----------------+
            | cluster_mode_type   | null       | 1        | 2               | 3               | 4               |
            +---------------------+------------+----------+-----------------+-----------------+-----------------+
            */
			function getClusterModeInfo(cluster_mode, cluster_mode_type) {
				let clusterModeType = 0;
				let inventoryModeType = 2;

				if (cluster_mode && cluster_mode_type === 1) {
					clusterModeType = 1;
				} else if (cluster_mode && cluster_mode_type > 1) {
					clusterModeType = 2;
					inventoryModeType = cluster_mode_type;
				}

				return {
					clusterModeType,
					inventoryModeType
				};
			}

			function getClusterModeType(clusterModeType, inventoryModeType) {
				if (clusterModeType === 1) {
					return 1;
				}

				if (clusterModeType === 2 && inventoryModeType) {
					return inventoryModeType;
				}

				return null;
			}
		}
	);
