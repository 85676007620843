'use strict';

angular.module('bringgApp').service('SentryService', function (ENV_CONFIGURATION, $injector) {
	var service = {};

	service.getIgnoredErrors = function () {
		return ['$digest already in progress', "Cannot read property '0' of null", 'Unauthorized'];
	};

	service.init = function () {
		if (typeof Sentry === 'undefined') {
			console.warn('Sentry is not defined. Skipping initialization.');
			return;
		}

		const didSentryAlreadyInstrumentedElsewhere =
			window.firstSentryInitSource !== undefined && window.firstSentryInitSource !== 'angular';

		if (Sentry && !ENV_CONFIGURATION.isLocalAgainstProd && ENV_CONFIGURATION.sentry_dsn) {
			Sentry.init({
				dsn: ENV_CONFIGURATION.sentry_dsn,
				environment: ENV_CONFIGURATION.environment,
				integrations: didSentryAlreadyInstrumentedElsewhere ? [] : undefined
			});
			Sentry.configureScope(scope => {
				scope.addEventProcessor(service.filterErrorMessages);
			});

			window.firstSentryInitSource = window.firstSentryInitSource || 'angular';
		} else {
			Sentry.close();

			window.firstSentryInitSource =
				window.firstSentryInitSource === 'angular' ? undefined : window.firstSentryInitSource;
		}
	};

	service.filterErrorMessages = function (event) {
		var errorMessage = _.get(event, 'exception.values[0].value');
		var result = event;

		_.forEach(service.getIgnoredErrors(), function (ignoredMessage) {
			if (_.includes(errorMessage, ignoredMessage)) {
				result = null;
			}
		});

		return result;
	};

	service.notify = function (exception, params) {
		if (typeof Sentry === 'undefined' || !Sentry.getCurrentHub().getClient()) {
			return;
		}

		if (!ENV_CONFIGURATION.sentry_dsn) {
			return;
		}

		exception = exception || {};
		var currentUser = $injector.get('Authentication').currentUser() || {};

		var defaultParams = {
			env: ENV_CONFIGURATION.environment,
			user: currentUser.id,
			merchant: currentUser.merchant_id
		};

		_.extend(defaultParams, params);

		Sentry.withScope(scope => {
			scope.setUser({
				id: currentUser.id,
				merchant_id: currentUser.merchant_id
			});
			scope.setTag('environment', ENV_CONFIGURATION.environment);
			scope.setExtras(defaultParams);
			Sentry.captureException(exception);
		});
	};

	service.init();
	return service;
});
