'use strict';

angular
	.module('bringgApp.directives')
	.directive('assignFleetField', function () {
		return {
			scope: {
				task: '=',
				getGridSelectedItems: '='
			},
			templateUrl: 'scripts/directives/assign-fleet/assign-fleet-field.html',
			link: function (scope) {
				scope.openAssignFleet(); // compile occur after first clicked
			},
			controller: 'AssignFleetFieldController'
		};
	})
	.controller(
		'AssignFleetFieldController',
		function (
			$scope,
			$log,
			$element,
			$timeout,
			TimezoneService,
			Tasks,
			Fleets,
			TimeManagerService,
			Authentication,
			ReactModals
		) {
			$scope.openAssignFleet = function () {
				return Authentication.featureFlags()
					.then(function (featureFlags) {
						return !!featureFlags.enable_manual_multiorder_fleet_selection;
					})
					.then(function (isFeatureEnabled) {
						const selectedItems = $scope.getGridSelectedItems();
						const clickedOnSelectedItem = selectedItems.some(task => task.id === $scope.task.id);
						ReactModals.openFleetAssigner({
							shouldTranslateInternalFleetName: true,
							taskId: $scope.task.id,
							gridSelectedItems:
								isFeatureEnabled && clickedOnSelectedItem && selectedItems.length > 1
									? selectedItems
									: [],
							onSelectedFleet: fleetId => {
								$scope.task.fleet_id = fleetId;
								Tasks.notifyChangesSkipThrottle();
							}
						});
					});
			};

			$scope.formatDescription = function () {
				return Fleets.getName($scope.task.fleet_id);
			};

			$scope.isFleetAssigned = function () {
				return _.isNumber($scope.task.fleet_id);
			};
		}
	);
