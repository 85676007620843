'use strict';

angular.module('bringgApp').run(function (AirbrakeService, SentryService) {
	window.ga =
		window.ga ||
		function () {
			AirbrakeService.notify(new Error('GA is deprecated'));
			SentryService.notify(new Error('GA is deprecated'));
		};
});
